import React from 'react'
import AppRouter from './appRouter'





function App() {
  return (
    <div>
      <AppRouter/>
      </div>
  )
}

export default App
