const Russ = "/rus";
const Engg = "/";




export {
    Russ,
    Engg,
};
